import { Pipe, PipeTransform } from '@angular/core';
import { ThreeViewFieldsDataSource } from '../shared/models/three-view-fields-data-source';
import { RoleType } from '@goatsports/core/data-access';
import { ROLE_PERMISSIONS } from '../shared/functions/role-permissions';

@Pipe({
  name: 'showRoleBasedNavigation',
  standalone: true,
})
export class ShowRoleBasedNavigationPipe implements PipeTransform {
  transform(
    currentUserRole: RoleType,
    routerUrl: string,
  ): ThreeViewFieldsDataSource[] {
    const navigation: ThreeViewFieldsDataSource[] =
      ROLE_PERMISSIONS[currentUserRole] ?? [];

    return navigation.map((x) => ({
      ...x,
      selected: this.isMatchingBasePath(routerUrl, x.id as string),
    }));
  }

  private isMatchingBasePath(fullPath: string, basePath: string) {
    const sanitizePath = (path: string) => '/' + path.replace(/^\/+|\/+$/g, '');

    const fullPathWithoutQuery = fullPath.split(/[?#]/)[0];

    fullPath = sanitizePath(fullPathWithoutQuery);
    basePath = sanitizePath(basePath);

    if (fullPath === basePath) return true;

    return fullPath.startsWith(basePath + '/');
  }
}
