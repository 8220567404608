<ng-container *transloco="let t">
  <ejs-appbar colorMode="Primary">
    <div class="e-appbar-spacer">
      <img
        ngSrc="../../assets/images/goat-sports-logo.png"
        alt="GOAT logo"
        priority
        width="92"
        height="40"
      />
    </div>

    @let showToolbarButtons = currentUserRole | showToolbarButtons;

    @if (showToolbarButtons) {
      <div class="badge-block margin-button-badge">
        <ejs-tooltip
          opensOn="Hover"
          id="tooltip"
          [content]="t('Chat')"
          target="#chat"
          position="TopLeft"
        >
          <button
            id="chat"
            [routerLink]="'/chat'"
            routerLinkActive="active-chat"
            ejs-button
            cssClass="e-btn e-inherit e-icons e-comment-show"
          ></button>
          @if (unreadMessagesCount(); as unreadMessagesCount) {
            <span
              class="e-badge e-badge-secondary e-badge-notification e-badge-overlap leftBottom"
              >{{ unreadMessagesCount | shortenCounter }}</span
            >
          }
        </ejs-tooltip>
      </div>

      <div class="badge-block margin-button-badge">
        <button
          ejs-dropdownbutton
          target="#listview"
          cssClass="e-inherit e-caret-hide"
          class="notification-button margin-button"
        >
          <ejs-tooltip
            opensOn="Hover"
            id="tooltip"
            [content]="t('Notification')"
            target="#notification"
            position="TopLeft"
          >
            <span id="notification" class="e-icons e-notes"> </span>
            <span class="down-arrow"></span>
          </ejs-tooltip>

          @if (unreadNotificationsCount(); as unreadNotificationsCount) {
            <span
              class="e-badge e-badge-secondary e-badge-messages e-badge-overlap leftBottom"
              style="top: 2px !important"
              >{{ unreadNotificationsCount | shortenCounter }}</span
            >
          }
        </button>
      </div>

      <goatsports-notifications-list id="listview" />
    }

    <ejs-menu
      [items]="menuItems()"
      cssClass="e-inherit"
      (select)="executeAction($event)"
    >
      <ng-template #template let-data>
        @if (data.properties.text === ProfileMenuItem.Icon) {
          <span class="e-icons e-user"> </span>
        } @else {
          <div class="language-selector-container">
            <span
              [ngClass]="{
                selected: data.properties.id === ProfileMenuItem.Language,
              }"
            >
              {{ t(data.properties.text) }}
            </span>
          </div>
        }
      </ng-template>
    </ejs-menu>
  </ejs-appbar>

  <ejs-sidebar
    #sidebar
    width="220"
    dockSize="64"
    target=".main-sidebar-content"
    [enableDock]="true"
    [type]="'Push'"
    [enableGestures]="false"
    (click)="sidebar.toggle()"
    [animate]="true"
    [enablePersistence]="true"
  >
    <div class="row">
      <section>
        @for (
          data of currentUserRole | showRoleBasedNavigation: routerUrl();
          track data.id;
          let i = $index
        ) {
          @if (sidebar.isOpen) {
            <div
              class="sidebar-tooltip"
              style="display: flex; align-items: center"
              [id]="'target' + i"
              (click)="$event.stopPropagation()"
              [routerLink]="data.id"
              [routerLinkActive]="['e-active']"
              [replaceUrl]="false"
            >
              <goatsports-icon-status
                [dataNav]="{ name: data.text, isSelected: data.selected }"
              />
              <p>{{ t(data.text!) }}</p>
            </div>
          } @else {
            <div class="collapsed-container">
              <ejs-tooltip
                opensOn="Hover"
                class="sidebar-tooltip"
                [content]="t(data.text!)"
                [target]="'#target' + i"
                position="BottomRight"
                (click)="$event.stopPropagation()"
                [routerLink]="data.id"
                [routerLinkActive]="['e-active']"
                [replaceUrl]="false"
              >
                <goatsports-icon-status
                  [id]="'target' + i"
                  [dataNav]="{ name: data.text, isSelected: data.selected }"
                />
              </ejs-tooltip>
            </div>
          }

          @if (data.hasChildren) {
            <div style="display: flex; justify-content: center">
              <hr />
            </div>
          }
        }
      </section>

      <div style="display: flex; justify-content: center">
        <hr style="margin: 0 !important" />
      </div>

      <div class="collapse-row">
        <span
          [ngClass]="sidebar.isOpen ? 'collapse-icon' : 'expand-icon'"
        ></span>
        @if (sidebar.isOpen) {
          <span>{{ t('Collapse') }}</span>
        }
      </div>
    </div>
  </ejs-sidebar>

  <div class="main-sidebar-content">
    <div style="height: 100%">
      @if (!isErrorThrown()) {
        <router-outlet />
      } @else {
        <goatsports-error-messages />
      }
    </div>
  </div>
</ng-container>
