import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'goatsports-icon-status',
  standalone: true,
  imports: [NgClass],
  templateUrl: './icon-status.component.html',
  styleUrls: ['./icon-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconStatusComponent {
  dataNav = input.required<{
    isSelected: boolean | undefined;
    name: string | undefined;
  }>();
}
