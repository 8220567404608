import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { setCulture } from '@syncfusion/ej2-base';
import { TranslocoService } from '@jsverse/transloco';
import { switchMap, tap } from 'rxjs';
import { ApplicationStateService } from './shared/services/application-state.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'goatsports-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  private readonly translocoService = inject(TranslocoService);
  private readonly applicationStateService = inject(ApplicationStateService);

  languageChanges = toSignal(
    this.translocoService.langChanges$.pipe(
      switchMap((language) =>
        this.translocoService.load(language).pipe(
          tap(() => {
            this.applicationStateService.languageChanged = language;
            setCulture(language);
          }),
        ),
      ),
    ),
  );
}
