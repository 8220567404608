import { Pipe, type PipeTransform } from '@angular/core';
import { RoleType } from '@goatsports/core/data-access';

@Pipe({
  name: 'showToolbarButtons',
  standalone: true,
})
export class ShowToolbarButtonsPipe implements PipeTransform {
  transform(currentUserRole: RoleType): boolean {
    return (
      currentUserRole !== RoleType.Translator &&
      currentUserRole !== RoleType.SuperAdmin
    );
  }
}
