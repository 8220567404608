import { DOCUMENT, NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  MenuEventArgs,
  MenuModule,
  SidebarModule,
  ToolbarModule,
  TreeViewModule,
} from '@syncfusion/ej2-angular-navigations';
import { RouteConstants } from '../shared/constants/route.constant';
import { DropDownButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { AppBarModule } from '@syncfusion/ej2-angular-navigations';
import { ProfileMenuItem } from '../shared/models/profile-menu-item';
import { ErrorMessagesComponent } from '../shared/components/error-messages/error-messages.component';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { environment } from '../../environments/environment';
import { ShellService } from './shell.service';
import { IconStatusComponent } from './icon-status/icon-status.component';
import { RoleType } from '@goatsports/core/data-access';
import { ShortenCounterPipe } from '@goatsports/shared/util';
import { ShowToolbarButtonsPipe } from './show-toolbar-buttons.pipe';
import { NotificationsListComponent } from '../shared/components/notifications-list/notifications-list.component';
import { ShowRoleBasedNavigationPipe } from './show-role-based-navigation.pipe';
import { TranslocoModule } from '@jsverse/transloco';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'goatsports-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgOptimizedImage,
    TranslocoModule,
    TooltipModule,
    SidebarModule,
    ToolbarModule,
    TreeViewModule,
    ButtonModule,
    AppBarModule,
    RouterModule,
    ShortenCounterPipe,
    ShowToolbarButtonsPipe,
    ShowRoleBasedNavigationPipe,
    DropDownButtonModule,
    MenuModule,
    NotificationsListComponent,
    ErrorMessagesComponent,
    IconStatusComponent,
  ],
  providers: [ShellService],
})
export default class ShellComponent {
  private readonly router = inject(Router);
  private readonly shellService = inject(ShellService);
  private readonly document = inject(DOCUMENT);

  readonly ProfileMenuItem = ProfileMenuItem;
  readonly RoleType = RoleType;

  currentUserRole = this.shellService.currentUserRole;
  menuItems = this.shellService.menuItems;
  unreadMessagesCount = this.shellService.unreadMessagesCount;
  unreadNotificationsCount = this.shellService.unreadNotificationsCount;

  isErrorThrown = this.shellService.isErrorThrown;
  language = this.shellService.language;
  routerUrl = this.shellService.routerUrl;

  navigate(url: string) {
    this.router.navigate([url]);
  }

  private navigateToChangePassword() {
    const url = new URL(
      `${environment.authorityPortalUrl}/${RouteConstants.CHANGE_PASSWORD_ROUTE}`,
    );
    url.searchParams.append('email', this.shellService.currentUserEmail);
    url.searchParams.append('returnUrl', this.document.baseURI);
    url.searchParams.append('language', this.language());

    location.href = url.href;
  }

  private navigateToProfile() {
    this.router.navigate([RouteConstants.PROFILE_ROUTE]);
  }

  executeAction(event: MenuEventArgs) {
    switch (event.item.text) {
      case ProfileMenuItem.Profile:
        this.navigateToProfile();
        break;
      case ProfileMenuItem.ChangePassword:
        this.navigateToChangePassword();
        break;
      case ProfileMenuItem.Logout:
        this.shellService.logout();
        break;
    }
    if (
      (event.item as any).parentObj.properties.text === ProfileMenuItem.Language
    ) {
      this.shellService.changeLanguage(event.item.text as string);
    }
  }
}
